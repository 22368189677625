import React, { useEffect } from "react";
import { Row  } from "reactstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useGetApi } from "../../Api/useGetApi";
import { API } from "../../Api/ApiList";
import { useCreatorContext } from "../../Context/Creator/CreatorContext";
import { CreatorDetails } from "./CreatorDetails";
import { CreatorPerformance } from "./CreatorPerformance";
import { Image} from "../../AbstractElement";
import { dynamicImage } from "../../Helper";


export const CreatorProfile = () => {

  const { id } = useParams();
  const { saveSearch } = useCreatorContext();
  const { data: user, refetch: creatorProfileRefetch, isLoading: creatorProfileLoading } = useGetApi({ url: `${API.creator.getCreatorDetailsApi}?id=${id}`, options: { enabled: false, refetchOnWindowFocus: false }, });
  const navigate = useNavigate();

  useEffect(() => {
    creatorProfileRefetch()
  }, [])

  useEffect(() => {
    // Add class when loading starts
    if (creatorProfileLoading) {
      document.body.classList.add('custom-bg-overscroll');
    } else {
      document.body.classList.remove('custom-bg-overscroll');
    }
  
    // Cleanup function to remove class when the component is unmounted or page changes
    return () => {
      document.body.classList.remove('custom-bg-overscroll');
    };
  }, [creatorProfileLoading]);


  const handleBackToSearch = () => {
    // Create a query string from the params
    const queryString = new URLSearchParams(saveSearch).toString();

    // Navigate to /creator with the query parameters
    navigate(`/creator?${queryString}`); 
  };

  return <div className="container">
     { creatorProfileLoading && (
        <div className="loading-overlay d-flex align-items-center justify-content-center gap-2 flex-column">
          <div class="custom-loader"></div>
          <div className="loading-message">Fetching Influencers Details... </div>
        </div>
      )}
    
    <div className="user-profile mb-5">
        <Row className="gy-4">
          <div className="header-breadcrumbs-box">
                <div className="custom-back-btn" onClick={handleBackToSearch}>
                  <Image className="back-arrow-custom" src={dynamicImage("svg/left-arrow.svg")} />
                  <span className="d-sm-block d-none" >Back to search</span></div> 
        </div>
          <CreatorDetails user={user} />
          <CreatorPerformance user={user} />
          {/* <CreatorMediaDetails user={user} /> */}

          {/* <Col lg={12}>
          <ul className="user-tab">
            <li className="active"> Performance</li>
            <li> Comment analysis
              <span className="badge">Ai powered</span>
            </li>
            <li>Growth & interests </li>
            <li>Audience </li>
          </ul>
        </Col>
        <Col lg={12}>
          <ul>
            <li className="performance-tab active">
                <h4>Performance</h4>
                <ul>
                  <li>
                    <div className="d-flex">
                      <Image src={dynamicImage("svg/user.svg")} />
                      <h3>20.28M</h3>
                    </div>
                    <p>Followers</p>
                    <span className="badge">81% Real</span>
                  </li>
                  <li>
                    <div className="d-flex">
                      <h3>160.78%</h3>
                    </div>
                    <p>Engagement rate</p>
                    <span className="badge">159.7% higher than median</span>
                  </li>
                  <li>
                    <div className="d-flex">
                      <h3>-</h3>
                    </div>
                    <div className="d-flex">
                      <div>
                        <p>Sponsored posts</p>
                        <p>performance</p>
                      </div>
                      <Image src={dynamicImage("svg/clock.svg")} />
                    </div>
                  </li>
                </ul>
            </li>
          </ul>
        </Col> */}
        </Row>
    </div>
  </div>;
};
