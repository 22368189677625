import React, { useEffect } from "react";
import { Container, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { FormatListBulleted } from "@mui/icons-material";

import { dynamicImage } from "../../Helper";
import SearchByFilters from "./SearchByFilters";
import SearchByProfile from "./SearchByProfile";
import { Btn, Image } from "../../AbstractElement";
import CommonBreadcrumb from "../Common/CommonBreadcrumb";
import { useCreatorContext } from "../../Context/Creator/CreatorContext";
import { useCreatorFavoriteContext } from "../../Context/Creator/CreatorFavoriteContext";
import { useCreatorProfileContext } from "../../Context/Creator/CreatorProfileContext";
import { useGetApi } from "../../Api/useGetApi";
import { API } from "../../Api/ApiList";
import useDebounce from "../../Hooks/useDebounce";
import {Category} from "../../Data/CategoryData";

const FindCreatorContainer = () => {
  const { 
    setTagsOption, 
    setCategoryOption, 
    tagsParam, 
    setChannelTopicOption, 
    channelTopicParam, 
    selectTab, 
    setSelectTab 
  } = useCreatorContext();
  const { toggleCreatorListModal } = useCreatorFavoriteContext();
  const { clearSearch } = useCreatorProfileContext();

  // Debounce the parameter
  const debouncedChannelTopicsParam = useDebounce(channelTopicParam, 500);
  const debouncedTagParam = useDebounce(tagsParam, 500);

  // Fetch data based on Channel Topics
  const { data: topicData, refetch: topicRefetch, isLoading: topicIsLoading } = useGetApi({
    url: API.creator.getCreatorChannelTopicsApi,
    params: { topic: debouncedChannelTopicsParam || "" }, // Default fetch with empty string
    options: { enabled: false, refetchOnWindowFocus: false },
  });
  
  // Fetch data based on Tags
  const { data: tagData, refetch: tagRefetch, isLoading: tagIsLoading } = useGetApi({
    url: API.creator.getCreatorTagApi,
    params: { tag: debouncedTagParam },
    options: { enabled: false, refetchOnWindowFocus: false },
  });

  useEffect(() => {
    // Fetch default topics on component load or when the parameter is empty
    if (channelTopicParam.trim() === "") {
      topicRefetch();
    } else if (debouncedChannelTopicsParam.trim() !== "") {
      topicRefetch();
    } else {
      setChannelTopicOption([]);
    }
  }, [channelTopicParam, debouncedChannelTopicsParam, topicRefetch, setChannelTopicOption]);

  useEffect(() => {
    if (debouncedTagParam.trim() !== "") {
      tagRefetch();
    } else {
      setTagsOption([]);
    }
  }, [debouncedTagParam, setTagsOption, tagRefetch]);

  // Update options when data is fetched
  useEffect(() => {
    if (topicData && !topicIsLoading) {
      setChannelTopicOption(topicData.sort((a, b) => a.localeCompare(b)));
    }
    if (tagData && !tagIsLoading) {
      setTagsOption(tagData.sort((a, b) => a.localeCompare(b)));
    }
    setCategoryOption(Category.sort((a, b) => a.localeCompare(b))); // Set static category data
  }, [setChannelTopicOption, setTagsOption, setCategoryOption, Category, tagData, tagIsLoading, topicData, topicIsLoading]);

  return (
    <section className="st-space">
      <Container>
        <div className="breadcrumb-box">
          <CommonBreadcrumb title="Find Influencers"/>
          <p>Our database is currently limited and updated in longer cycles, so some info may vary & some influencers may be missing. We're actively working to expand and refresh it more often.</p>
        </div>
      </Container>
      <section className="creator-cards">
        <Container>
          <div className="lb-space">
            <div className="top-nav-header sb-space">
              <Nav tabs className=" border-0 creator-filter-tab">
                <NavItem>
                  <NavLink className={selectTab === 1 ? "active" : ""} onClick={() => {setSelectTab(1);clearSearch();}}>
                    <Image src={dynamicImage("svg/filter.svg")} alt="filter Icon" />Search by filters
                  </NavLink>
                  <NavLink className={selectTab === 2 ? "active" : ""} onClick={() => setSelectTab(2)}>
                    <Image src={dynamicImage("svg/user-search.svg")} alt="filter Icon" />Search by profile
                  </NavLink>
                </NavItem>
              </Nav>
              <Btn className="btn-pill-light add-creator-btn" onClick={toggleCreatorListModal}><FormatListBulleted />Your favorites</Btn>
            </div>
            <TabContent activeTab={selectTab}>
              <TabPane tabId={1}>
                <SearchByFilters topicIsLoading={topicIsLoading} tagIsLoading={tagIsLoading} categoryIsLoading={false} />
              </TabPane>
              <TabPane tabId={2}>
                <SearchByProfile selectTab={selectTab} />
              </TabPane>
            </TabContent>
          </div>
        </Container>
      </section>
    </section>
  );
};

export default FindCreatorContainer;
