import React, { Fragment, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { TextField } from "@mui/material";
import { Col, Row, Form, FormGroup, Spinner } from "reactstrap";
import * as yup from "yup";
import { Btn, Image } from "../../../AbstractElement";
import { dynamicImage } from "../../../Helper";
import getStorage, { KEYS } from "../../../Helper/getStorage";
import { useAuthContext } from "../../../Context/Auth/AuthContext";
import { useGetApi } from "../../../Api/useGetApi";
import { API } from "../../../Api/ApiList";
import { useEffect } from "react";

export const ProfileContainer = () => {
  const { getItem, setItem } = getStorage();
  const { name, email, account_type } = JSON.parse(getItem(KEYS.USER)) || {};
  const [isLoading, setIsLoading] = useState(false);

  const { refetch: userProfileRefetch } = useGetApi({ url: `${API.user.getProfileDetails}`, options: { enabled: false, refetchOnWindowFocus: false }, });

  // useEffect(() => {
  //   userProfileRefetch()
  // }, []);
  
  // Form validation schema
  const schema = yup.object({
    name: yup.string().required("Name is required"),
    email: yup.string().email("Invalid email address").required("Email is required"),
  });

  const { register, handleSubmit, formState: { errors }, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: name || "",
      email: email || "",
    },
  });

  const { handleUpdateProfile } = useAuthContext();
  const onSubmit = async (data) => {
    setIsLoading(true) // Start loading
    try {
      // Call the update profile API or context method
      await handleUpdateProfile(data);
  
      // Refetch the user details after the profile update
      const updatedUserDetails = await userProfileRefetch();
  
      // Update localStorage with the latest user details
      if (updatedUserDetails?.data) {
        setItem(KEYS.USER, JSON.stringify(updatedUserDetails.data));
      }
  
    } catch (error) {
      console.error("Error updating profile:", error);
    } finally {
      setIsLoading(false); // Stop loading
    }
  };


  const handleReset = () => {
    const userData = JSON.parse(getItem(KEYS.USER)) || {};
    reset({
      name: userData.name || "",
      email: userData.email || "",
    });
  };

  

  return (
    <Fragment>
      <div className="change-password">
        <div className="card">
          <div className="card-header text-start">
            <h4 className="mb-0">Update Your Profile</h4>
          </div>
          <div className="card-body">
            <div className="profile-page">
              <div className="profile-box">
                <div className="profile-wrap">
                  <Image src={dynamicImage("image.png")} alt="User" />
                  {/* <Image src={dynamicImage("user/2.jpg")} alt="User" /> */}
                  <div className="profile-detail">
                    <h5>{name}</h5>
                    <h6 className="text-capitalize">{account_type}</h6>
                  </div>
                </div>
              </div>
              <div className="general-details-wrap width-space w-100">
                <h5 className="inner-title">General Details</h5>
                <Form onSubmit={handleSubmit(onSubmit)} noValidate>
                  <Row className="g-sm-4 g-3">
                    <Col lg={6}>
                      <FormGroup className="position-relative mb-0">
                        <label htmlFor="name" className="form-label">
                          Name <span className="text-danger">*</span>
                        </label>
                        <TextField
                          fullWidth
                          id="name"
                          {...register("name")}
                          error={!!errors.name}
                          helperText={errors.name?.message}
                          placeholder="Enter your name"
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={6}>
                      <FormGroup className="position-relative mb-0">
                        <label htmlFor="email" className="form-label">
                          Email Address <span className="text-danger">*</span>
                        </label>
                        <TextField
                          disabled
                          fullWidth
                          id="email"
                          type="email"
                          {...register("email")}
                          error={!!errors.email}
                          helperText={errors.email?.message}
                          placeholder="Enter your email"
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12}>
                      <div className="d-flex justify-content-end profile-btn-group">
                        <Btn block className="btn-theme d-inline-block w-auto me-2" onClick={handleReset}
                        >
                            Reset
                        </Btn>
                        <Btn block className="btn-theme d-inline-block w-auto" type="submit" disabled={isLoading}>
                          {isLoading ? <>Update Profile <Spinner size="sm" /></> : "Update Profile"}

                        </Btn>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
