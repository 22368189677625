import { useNavigate } from "react-router-dom";

import { getLabelMapping } from "../Component/FindCreator/SearchByFilters/CreatorFilters/AddedFilterList";

export const useSetUrlParams = () => {
  const navigate = useNavigate();

  const setParam = (newParams) => {
    const searchParams = new URLSearchParams(window.location.search);

    Object.keys(newParams).forEach((key) => {
    
      if (newParams[key] !== undefined && newParams[key] !== null) {
        searchParams.set(key, newParams[key]);
      } else {
        searchParams.delete(key);
      }
    });
 
    navigate(
      {
        search: `?${searchParams.toString()}`,
      },
      { replace: true },
    );
  };

  return setParam;
};

export const getParams = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const getParam = {};

  for (const [key, value] of searchParams.entries()) {
    try {
      const decodedKey = decodeURIComponent(key);
      const decodedValue = decodeURIComponent(value);
      getParam[decodedKey] = decodedValue;
    } catch (error) {
      console.error(`Error decoding parameter: ${key} = ${value}`, error);
    }
  }

  return getParam;
};

// Removed all URL parameters
export const removeAllFilters = (platform) => {
  const searchParams = new URLSearchParams(window.location.search);

  // List of all possible URL parameters
  const urlKeys = ["hashtags", "tag", "brand","searchType", "channelTopic", "creatorMinFollower", "creatorMaxFollower", "engagementRate", "isVerify", "mostRecentPosts", "videoDurations", "videoCategory", "videoLanguage", "mentions", "biokeyword", "postKeyWord", "creatorMinContent", "creatorMaxContent", "creatorMinLike", "creatorMaxLike", "creatorMinComment", "creatorMaxComment", "creatorMinView", "creatorMaxView", "accountTypes", "category", "creatorLocations"];
  
  if (platform) {
    // If platform is provided, remove all URL params except 'platform'
    urlKeys.forEach((key) => {
      if (key !== "platform") {
        searchParams.delete(key);
      }
    });
  } else {
    // If platform is not provided, remove all parameters
    urlKeys.forEach((key) => {
      searchParams.delete(key);
    });
  }

  // Construct the new URL
  const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
  // Update the browser's URL without reloading the page
  window.history.replaceState(null, "", newUrl);
};

// Remove a tag from the filterTags state
export const removeTag = (tagToRemove, setFilterTags) => {
  // Get label mapping dynamically
  const labelMapping = getLabelMapping();

  // Remove the tag from the filterTags state
  setFilterTags((prevTags) => prevTags.filter((tag) => tag !== tagToRemove));

  // Update URL parameters
  const searchParams = new URLSearchParams(window.location.search);

  // Use the title to identify the parameter to remove
  const paramKey = Object.keys(labelMapping).find((key) => labelMapping[key] === tagToRemove.title);

  if (paramKey) {
    // Remove the parameter from the search params
    searchParams.delete(encodeURIComponent(paramKey));
  }

  // Check if any parameters are empty or null and remove them
  for (const [key, value] of searchParams.entries()) {
    if (value === "" || value === null || value === undefined) {
      searchParams.delete(key);
    }
  }

  // Construct new URL
  const newUrl = searchParams.toString() ? "?" + searchParams.toString() : window.location.pathname;
  window.history.replaceState(null, "", newUrl);

  // Call the API after updating the URL
  if (paramKey === "platform") {
    // Run removeAllFilters if the platform key was removed
    removeAllFilters();
  }
};
