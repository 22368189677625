import { AlternateEmail, Clear, InfoOutlined } from "@mui/icons-material";
import {
  FormControl,
  FormControlLabel,
  OutlinedInput,
  Radio,
  RadioGroup,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Form } from "reactstrap";

import { Btn, Image } from "../../../../AbstractElement";
import { getParams, useSetUrlParams } from "../../../../Api/UrlParams";
import { useCreatorContext } from "../../../../Context/Creator/CreatorContext";
import { dynamicImage } from "../../../../Helper";

const CreatorAiFilter = ({
  setParams,
  loading,
  setUrlParamsProcessed,
  setPage,
  setLimit,
}) => {
  const queryParams = new URLSearchParams(window.location.search);
  const platform = queryParams.get("platform"); // "youtube"
  const { aiSearch, setAiSearch, platFormSelectedItem, setSaveSearch, searchType, setSearchType } = useCreatorContext();
  const setParam = useSetUrlParams();
  const getParam = getParams();
  // const [searchType, setSearchType] = useState("onlyText"); // "onlyText" or "textImage"

  useEffect(() => {
    if (getParam.brand) {
      setAiSearch(getParam.brand);
    } else {
      setAiSearch("");
    }
  }, [getParam.brand, setAiSearch]);

  const submitSearch = async (e) => {
    e.preventDefault();

    // Ensure parameters are processed
    setUrlParamsProcessed(true);

    // Conditionally build the `searchType` value
    const effectiveSearchType = aiSearch ? searchType || 'onlyText' : null;

    // Update state with parameters
    setParams((prevParams) => ({
      ...prevParams,
      brand: aiSearch,
      platform: platFormSelectedItem?.title,
      ...(effectiveSearchType && { searchType: effectiveSearchType }), // Add searchType if aiSearch exists
    }));

    setPage(1);
    setLimit(25);

    setSaveSearch((prevParams) => ({
      ...prevParams,
      brand: aiSearch,
      platform: platFormSelectedItem?.title,
      ...(effectiveSearchType && { searchType: effectiveSearchType }), // Add searchType if aiSearch exists
    }));

    // Directly update `setParam` with relevant data
    const filteredParams = {
      brand: aiSearch,
      platform: platFormSelectedItem?.title,
      ...(effectiveSearchType && { searchType: effectiveSearchType }),
    };

    setParam(filteredParams);
  };
  return (
    <>
      <div className="ai-search-box mb-4">
        <p className="search-title">AI based recommendation  <span className="taglNews">New</span>
          <Tooltip title={`Please enter the Instagram brand handle you want to find influencers for. Our Al agent analyzes the context from your text(bio, posts), and images (profile, posts),
then matches influencers who share a similar style and focus based on their bios, posts, and thumbnails.`} placement={"right"}>
            <span>
              <InfoOutlined className="info-icon" fontSize="small" />
            </span>
          </Tooltip>
        </p>
        <div className="">
          {/* {platFormSelectedItem?.title === "instagram" &&
            <div className="ai-search-panel mb-1">
              <h5>{"AI-Powered search"}</h5>
              <span className="taglNews">New</span>
            </div>
          } */}
          <div className="w-100">
            <div className="top-filter-panel w-100 h-100">
              <Form className="form-search-box h-100 w-100" noValidate onSubmit={submitSearch}>
                <p className="label-text">Brand handle</p>
                <OutlinedInput
                  disabled={false}
                  startAdornment={<AlternateEmail className="info-icon" position="start" />}
                  endAdornment={
                    aiSearch.length > 0 && (
                      <Clear className="cursor-pointer" onClick={() => setAiSearch("")} />
                    )
                  }
                  className="w-100 me-0 ai-input"
                  placeholder="Please enter brand's active instagram handle"
                  // placeholder={platform === "youtube" ? "Please enter your active youtube handle e.g. yourbrand ..." : "Please enter your active instagram handle e.g. yourbrand ..."}
                  value={aiSearch}
                  onChange={(e) => setAiSearch(e.target.value)}
                />

                <Btn
                  className="btn-theme search-btn h-100"
                  type="submit"
                  disabled={!aiSearch.length || loading}
                >
                  <Image
                    src={dynamicImage("svg/search.svg")}
                    className="search-icon"
                    alt="Settings Icon"
                  />{" "}
                  Search
                </Btn>
              </Form>
            </div>
          </div>
          <div className="ai-search-panel mb-1 mt-3">
            {platform === "youtube" &&
              <>
                <h5>{"Refine your search with"}</h5>
                <FormControl component="fieldset" className="search-type-options">
                  <RadioGroup
                    row
                    value={searchType ? searchType : 'onlyText'}
                    onChange={(e) => setSearchType(e.target.value)}
                  >
                    <FormControlLabel
                      value="onlyText"
                      control={<Radio />}
                      label="Only Text"
                    />
                    <FormControlLabel
                      value="textImage"
                      control={<Radio />}
                      label="Text + Image"
                    />
                  </RadioGroup>
                </FormControl>
              </>
            }

          </div>
        </div>
      </div>
    </>
  );
};

export default CreatorAiFilter;