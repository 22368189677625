import { Clear } from "@mui/icons-material";
import React, { Fragment, useCallback, useEffect } from "react";

import { Btn } from "../../../../AbstractElement";
import { getParams, removeAllFilters, removeTag, useSetUrlParams } from "../../../../Api/UrlParams";
import { useCreatorContext } from "../../../../Context/Creator/CreatorContext";
import { formatInstagramNumber } from "../../../../Helper";
import { countriesList } from "../../../../Data/CountryData";
import { useCreatorFavoriteContext } from "../../../../Context/Creator/CreatorFavoriteContext";
import { videoLanguagesOptions } from "../../../../Data/VideoFilterData";

export const getLabelMapping = (isInstagram) => ({
  platform: "Platform",
  hashtags: "Hashtags",
  mentions: "Mentions",
  biokeyword: "Bio keyword",
  isVerify: "Is verify",
  mostRecentPosts: "Most recent post",
  engagementRate: "Engagement Rate",
  creatorFollower: isInstagram ? "Creator Follower" : "Creator Subscriber",
  creatorLike: "Avg Likes",
  creatorComment: "Avg Comments",
  creatorContent: "Avg Contents",
  creatorView: "Avg Views",
  brand: "AI Search",
  accountTypes: "Account type",
  category: "Categories",
  tag: "Tags", 
  creatorLocations: "Creator Locations",
  postKeyWord: "Post keyword",
  videoCategory: "Video Category",
  videoDurations: "Video Duration",
  videoLanguage: "Video Language",
  channelTopic: "Channel Topic",
  searchType :"Search Type"
});

const formatDisplayValue = (key, value) => {
  switch (key) {
    case "hashtags":
      return value.split(",").map((mention) => `#${mention}`).join(",");
    case "mentions":
      return value.split(",").map((mention) => `@${mention}`).join(",");
    case "engagementRate":
      return `> ${value} %`;
    default:
      return value;
  }
};

export const AddedFilterList = ({ setDataFetched, getAndSetParams, loading, setDefaultSort }) => {
  const {setSearchType, setVideoLanguages, setVideoCategoryMention, setChannelTopicList, platFormSelectedItem, setTagsList, setAccountType, setCategoryMention, setCreatorLocation, setCommentValue, setAverageViewValue, setLikeValue, setContentValue, removeUrlParams, filterTags, setFilterTags, setFollowerRange, setAiSearch } = useCreatorContext();
  const { setSelectedFilterRows, setIsAllFilterChecked } = useCreatorFavoriteContext();
  const isInstagram = platFormSelectedItem?.title === "instagram";

  // Get label mapping dynamically
  const labelMapping = getLabelMapping(isInstagram);

  const setParam = useSetUrlParams();

  const createTag = (title, value, key) => ({ title, value: value.trim(), key });

  const createRangeTag = (min, max, key, label) => {
    let displayValue = "";
    if (min) displayValue += `${formatInstagramNumber(parseInt(min, 10))}`;
    if (max) displayValue += ` - ${formatInstagramNumber(parseInt(max, 10))}`;
    return createTag(label, displayValue, key);
  };

  const generateTagsFromParams = useCallback(() => {
    const getParam = getParams();
    const initialTags = [];
    if (getParam.creatorMinFollower || getParam.creatorMaxFollower) {
      initialTags.push(createRangeTag(getParam.creatorMinFollower, getParam.creatorMaxFollower, "creatorFollower", labelMapping.creatorFollower));
    }

    if (getParam.creatorMinLike || getParam.creatorMaxLike) {
      initialTags.push(createRangeTag(getParam.creatorMinLike, getParam.creatorMaxLike, "creatorLike", labelMapping.creatorLike));
    }

    if (getParam.creatorMinComment || getParam.creatorMaxComment) {
      initialTags.push(createRangeTag(getParam.creatorMinComment, getParam.creatorMaxComment, "creatorComment", labelMapping.creatorComment));
    }

    if (getParam.creatorMinView || getParam.creatorMaxView) {
      initialTags.push(createRangeTag(getParam.creatorMinView, getParam.creatorMaxView, "creatorView", labelMapping.creatorView));
    }

    if (getParam.creatorMinContent || getParam.creatorMaxContent) {
      initialTags.push(createRangeTag(getParam.creatorMinContent, getParam.creatorMaxContent, "creatorContent", labelMapping.creatorContent));
    }

    Object.entries(getParam).forEach(([key, value]) => {
      if (["creatorMinFollower", "creatorMaxFollower", "creatorMinLike", "creatorMaxLike", "creatorMinComment", "creatorMaxComment", "creatorMinView", "creatorMaxView", "creatorMinContent", "creatorMaxContent"].includes(key)) {
        return;
      }

      if (["engagementRate"].includes(key) && parseFloat(value) === 0) {
        setParam({ [key]: null });
        return;
      }

      const label = labelMapping[key] || key;
      initialTags.push(createTag(label, formatDisplayValue(key, value), key));
    });

    return initialTags;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setParam]);

  useEffect(() => {
    const initialTags = generateTagsFromParams();
  
    setFilterTags((prevTags) => {
      if (JSON.stringify(prevTags) !== JSON.stringify(initialTags)) {
        return initialTags;
      }
      return prevTags;
    });
  }, [generateTagsFromParams, setFilterTags]);

  const handleRemoveAllFilters = useCallback(() => {
    removeAllFilters();
    setDataFetched(false);
    removeUrlParams();
    setDefaultSort();
    setSelectedFilterRows([]);
    setIsAllFilterChecked(false);
    setFilterTags([])
    // setSearchType("")
    
  }, [removeUrlParams, setDataFetched, setDefaultSort,setSearchType, setIsAllFilterChecked, setSelectedFilterRows, setParam]);

  const handleRemoveTag = useCallback(
    (tag) => {     
      const paramKey = Object.keys(labelMapping).find((key) => labelMapping[key] === tag.title);
      removeTag(tag, setFilterTags);
      console.log("🚀 ~ AddedFilterList ~ paramKey:", paramKey)
      switch (paramKey) {
        case "creatorFollower":
          setParam({ creatorMinFollower: null, creatorMaxFollower: null });
          setFollowerRange({ from: null, to: null });
          break;
        case "creatorLike":
          setParam({ creatorMinLike: null, creatorMaxLike: null });
          setLikeValue({ from: null, to: null });
          break;
        case "creatorComment":
          setParam({ creatorMinComment: null, creatorMaxComment: null });
          setCommentValue({ from: null, to: null });
          break;
        case "creatorView":
          setParam({ creatorMinView: null, creatorMaxView: null });
          setAverageViewValue({ from: null, to: null });
          break;
        case "creatorContent":
          setParam({ creatorMinContent: null, creatorMaxContent: null });
          setContentValue({ from: null, to: null });
          break;
        case "creatorLocations":
          setCreatorLocation([]);
          break;
        case "videoLanguage":
          setVideoLanguages([]);
          break;
        case "tag":
          setTagsList([]);
          break;
        case "category":
          setCategoryMention([]);
          break;
        case "accountTypes":
          setAccountType([]);
          break;
        case "channelTopic":
          setChannelTopicList([]);
          break;
        case "videoCategory":
          setVideoCategoryMention([]);
          break;
        case "brand": // Handle brand removal
          setAiSearch('');
          setSearchType('');
          setParam({ brand: null, searchType: null });
          break;
        case "searchType": // Handle searchType removal
          setSearchType('');
          setParam({ searchType: null });
          break;
        default:
          break;
      }

      setSelectedFilterRows([]);
      setIsAllFilterChecked(false);

      const remainingTags = filterTags.filter((t) => t.key !== tag.key);
      if (remainingTags.length === 0 || paramKey === "platform") {
        removeAllFilters();
        setDefaultSort();
        removeUrlParams();
      } else if (paramKey !== "platform") {
        getAndSetParams();
      }
    },
    [filterTags, getAndSetParams, setSearchType,labelMapping, removeUrlParams, setAccountType, setAverageViewValue, setCategoryMention, setChannelTopicList, setCommentValue, setContentValue, setCreatorLocation, setDefaultSort, setFilterTags, setFollowerRange, setIsAllFilterChecked, setLikeValue, setParam, setSelectedFilterRows, setTagsList, setVideoCategoryMention, setVideoLanguages],
  );
   
  const paramsKey = ["platform", "channelTopic", "tag"];

  return (
    <Fragment>
      {filterTags.length > 0 && (
        <div className="filter-content-wrap">
          <ul className="tags-list-box">
            {filterTags.map((tag, index) => (
              <li key={index} className="filter-tags">
                <div className="tag-detail">
                  <h5>{tag.title} :</h5>
                  <span className={paramsKey.includes(tag.key) ? "text-capitalize" : ""}>
                    {tag.key === "creatorLocations"
                      ? tag.value
                          .split(",")
                          .map((code) => {
                            const countryObject = countriesList.find((country) => country.code === code.trim());
                            return countryObject ? countryObject.name : code;
                          })
                          .join(", ")
                      : tag.key === "videoLanguage"
                      ? tag.value
                          .split(",")
                          .map((code) => {
                            const videoLanguageObject = videoLanguagesOptions.find((country) => country.code === code.trim());
                            return videoLanguageObject ? videoLanguageObject.name : code;
                          })
                          .join(", ")
                      : tag.value}
                  </span>
                </div>
                <Btn className="p-0 border-0" disabled={loading} onClick={() => handleRemoveTag(tag)}>
                  <Clear fontSize="small" />
                </Btn>
              </li>
            ))}
          </ul>
          <Btn className="border-0" onClick={handleRemoveAllFilters} disabled={loading}>
            Remove all filters
          </Btn>
        </div>
      )}
    </Fragment>
  );
};
