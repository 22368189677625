import Chip from "@mui/material/Chip";
import React, { Fragment } from "react";
import TextField from "@mui/material/TextField";
import { ExpandMore, Close } from "@mui/icons-material";
import Autocomplete from "@mui/material/Autocomplete";
import { FilterListSkeleton } from "../../Layout/Loader/SkeltonLoaders";

const AutocompleteChips = ({
  options,
  value,
  setValue,
  inputValue,
  setInputValue,
  placeholder,
  noCapital,
  isLoading,
}) => {
  const handleChange = (event, newValue) => {
    if (Array.isArray(newValue)) {
      setValue(
        newValue.map((item) =>
          typeof item === "string" ? item : `${item.name}, ${item.code}`
        )
      );
    } else if (newValue) {
      setValue((prev) => {
        const formattedValue =
          typeof newValue === "string"
            ? newValue
            : `${newValue.name}, ${newValue.code}`;

        if (!prev.includes(formattedValue)) {
          return [...prev, formattedValue];
        }
        return prev;
      });
    }
    setInputValue("");
  };

  const handleDelete = (itemToDelete) => {
    setValue((prev) => prev.filter((item) => item !== itemToDelete));
  };

  const filteredOptions = inputValue ? options : options;

  return (
    <Fragment>
      <Autocomplete
        loading={isLoading}
        loadingText={<FilterListSkeleton loop={5} />}
        popupIcon={<ExpandMore fontSize="small" />}
        clearIcon={false}
        options={filteredOptions}
        getOptionLabel={(option) =>
          typeof option === "string" ? option : `${option.name}`
        }
        value={null}
        onChange={handleChange}
        inputValue={inputValue}
        onInputChange={(e, newInputValue) => setInputValue(newInputValue)}
        renderInput={(params) => (
          <TextField {...params} placeholder={placeholder} />
        )}
        renderOption={(props, option) => {
          const formattedOption =
            typeof option === "string"
              ? option
              : `${option.name}, ${option.code}`;
          const isSelected = value.includes(formattedOption);

          return (
            <li
              {...props}
              className={`px-3 py-2 d-flex flex-row justify-content-between align-items-center ${isSelected ? "bg-light text-primary" : ""
                }`}
              style={{
                backgroundColor: isSelected ? "#e3f2fd" : "transparent", // Highlight with light blue
                borderRadius: "4px",
              }}
            >
              <span>
                {typeof option === "string" ? option : option.name}
              </span>
              {isSelected && (
                <Close type="button" className=""
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent Autocomplete item selection
                    handleDelete(formattedOption);
                  }} fontSize="small" style={{ marginRight: "2px" }} />
              )}
            </li>
          );
        }}
      />

      {value.length > 0 && (
        <div className="chip-wrapper">
          {value?.map((item, index) => (
            <Fragment key={index}>
              <Chip
                className={!noCapital ? "text-capitalize" : ""}
                label={item}
                onDelete={() => handleDelete(item)}
              />
            </Fragment>
          ))}
        </div>
      )}
    </Fragment>
  );
};

export default AutocompleteChips;
