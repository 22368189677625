import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { Col, Form, FormGroup, Row, TabPane } from "reactstrap";

import { countriesCode } from "../../../Data/CountryCode";
import { dynamicImage, dynamicSvg } from "../../../Helper";
import PassStrength from "../Common/PassStrength";
import { Btn, Image } from "../../../AbstractElement";
import { useAuthContext } from "../../../Context/Auth/AuthContext";
import useApiState from "../../../Hooks/useApiState";

const AuthForm = ({ account }) => {
  const [isSignUpSubmitted, setIsSignUpSubmitted] = useState(false);
  const [passwordVisibility, setPasswordVisibility] = useState({ password: false, confirmPassword: false, });
  const { loading, setLoading } = useApiState()

  const togglePasswordVisibility = (field) => {
    setPasswordVisibility((prev) => ({ ...prev, [field]: !prev[field], }));
  };

  const { getPasswordStrength, CreatorSignUpSchema, handleSignUp } = useAuthContext();
  const [passwordStrength, setPasswordStrength] = useState("");

  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCountryCode, setSelectedCountryCode] = useState('+91');
  const [selectedCountryflag, setSelectedCountryflag] = useState('https://flagcdn.com/w320/in.png');
  const dropdownRef = useRef(null); // Ref for dropdown
  const navigate = useNavigate();

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSelect = (dial_code,flag) => {
    setSelectedCountryCode(dial_code);
    setSelectedCountryflag(flag)
    setIsOpen(false);
  };

  // Filter countries based on search input
  const filteredCountries = countriesCode.filter(country =>
    country.dial_code.includes(searchTerm)
  );


  const { register, handleSubmit, formState: { errors }, trigger } = useForm({
    resolver: yupResolver(CreatorSignUpSchema),
    // defaultValues: {
    //   phone_number: "7990661829", // Set default value for phone number
    // },
  });

  const handlePasswordChange = async (event) => {
    const password = event.target.value;
    setPasswordStrength(getPasswordStrength(password));
    await trigger(["password"]);
  };

  const [handlePassMessage, setHandlePassMessage] = useState(false);
  useEffect(() => {
    if (passwordStrength === "strong") {
      setHandlePassMessage(true);
    } else {
      setHandlePassMessage(false);
    }
  }, [passwordStrength, setHandlePassMessage])

  const onSubmit = (data) => {
    // Combine the selected country code and the phone number field
    const formattedData = {
      ...data,
      phone_number: `${selectedCountryCode}${data.phone_number}`,
    };

    handleSignUp({ data: formattedData, navigate, account, setLoading })
  };

  // Close dropdown on outside click
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  return (
    <TabPane tabId={1}>
      <Form className="auth-login_form" onSubmit={handleSubmit(onSubmit)} noValidate>
        <FormGroup className="position-relative">
          <Image src={dynamicImage("svg/user.svg")} className="form-icon" />
          <input {...register("name")} className="form-control" placeholder={account === "brand" ? "Enter Brand Name" : "Enter Your Name"} type="text" />
          {errors.name && (
            <span className="required-note">
              <small>{errors.name.message}</small>
            </span>
          )}
        </FormGroup>

        <FormGroup className="position-relative">
          <Image src={dynamicImage("svg/sms.svg")} className="form-icon" />
          <input {...register("email")} className="form-control" placeholder="Enter Email Address" type="email" />
          {errors.email && (
            <span className="required-note">
              <small>{errors.email.message}</small>
            </span>
          )}
        </FormGroup>

        <FormGroup className="position-relative">
          <Image src={dynamicImage("svg/phone.svg")} className="form-icon" />
          <Row>
            <Col xs={4}> 
              <div className="dropdown form-control " ref={dropdownRef}>
                <div className="dropdown-btn" onClick={toggleDropdown}>
                <img src={selectedCountryflag} alt="flag" className="flag" />{selectedCountryCode} 
                <svg className="dropdown-arrow">
              <use href={`${dynamicSvg('admin-sprite.svg')}#drop-arrow`}></use>
            </svg>
                </div> 
                {isOpen && (
                  <>
                  <div className="dropdown-content custom-scrollbar">
                   <input type="Number" className="search-box form-control" placeholder="search" value={searchTerm} onChange={handleSearchChange}/> 
                   
                    {filteredCountries.map((country, index) => (
                      <div
                        key={index}
                        className="dropdown-item" 
                        onClick={() => handleSelect(country.dial_code, country.flag)}
                      >
                        <img src={country.flag} alt={country.name} className="flag" />
                        {country.dial_code}
                      </div>
                    ))}
                  </div>
                  </>
                )}
                
              </div>
            </Col>
            <Col xs={8}>
              <input {...register("phone_number")} className="form-control ps-3" placeholder="Enter phone Number" type="text" />
            </Col>
          </Row>
          {errors.phone_number && (
            <span className="required-note">
              <small>{errors.phone_number.message}</small>
            </span>
          )}
        </FormGroup>

        <FormGroup className="position-relative">
          <Image src={dynamicImage("svg/lock.svg")} className="form-icon" />
          <input {...register("password")} onChange={handlePasswordChange} className="form-control" placeholder="Password" type={passwordVisibility.password ? "text" : "password"} />
          <div className="password-wrap" onClick={() => togglePasswordVisibility("password")}>
            <Image src={dynamicImage(`svg/${passwordVisibility.password ? "eye" : "eye-slash"}.svg`)} />
          </div>
          {!handlePassMessage && errors.password && (
            <span className="required-note">
              <small>{errors.password.message}</small>
            </span>
          )}
        </FormGroup>

        {passwordStrength && <PassStrength passwordStrength={passwordStrength} />}
        <FormGroup className="position-relative">
          <Image src={dynamicImage("svg/lock.svg")} className="form-icon" />
          <input {...register("confirmPassword")} className="form-control" placeholder="Confirm Password" type={passwordVisibility.confirmPassword ? "text" : "password"} />
          <div className="password-wrap" onClick={() => togglePasswordVisibility("confirmPassword")}>
            <Image src={dynamicImage(`svg/${passwordVisibility.confirmPassword ? "eye" : "eye-slash"}.svg`)} />
          </div>
          {errors.confirmPassword && (
            <span className="required-note">
              <small>{errors.confirmPassword.message}</small>
            </span>
          )}
        </FormGroup>

        {/* <div className="terms-wrap">
          <input type="checkbox" className="check-box" id="terms" {...register("terms")} />
           <label htmlFor="terms" className={`terms ${isSignUpSubmitted && errors.terms ? "text-danger" : isSignUpSubmitted ? "text-gray" : ""}`}>
            By creating account, you agree to our <Link>Term and Conditions.</Link>
          </label> 
        </div> */}

        <Btn block className="btn-theme" type="submit" onClick={() => setIsSignUpSubmitted(true)} disabled={loading} loading={loading} >
          Sign up
        </Btn>
      </Form>
    </TabPane>
  );
};


export default AuthForm;
