import { Slider } from "@mui/material";
import React from "react";
import { Col } from "reactstrap";

import { useCreatorContext } from "../../../../Context/Creator/CreatorContext";
import FilterWrapper from "../../../Common/FilterWrapper";
import CustomRangeFromTo from "../../../Common/CustomRangeFromTo";
import { averageLikeOption, averageViewOption } from "../../../../Data/FindCreatorData";
import { checkActiveRang } from "../../../../Helper";

export const PerformanceFilterList = () => {
  const { viewFromOptions, viewToOptions,commentToOptions, commentFromOptions, likeFromOptions, likeToOptions, platFormSelectedItem, likeValue, setLikeValue, engagementValue, setEngagementValue, commentValue, setCommentValue, averageViewValue, setAverageViewValue } = useCreatorContext();

  const isInstagram = platFormSelectedItem?.title === "instagram";

  return (
    <Col sm={6}>
      <div className="filter-box pb-4">
        <h5 className="inner-list">Performance</h5>
        <ul className="menu-list-wrap">
          <FilterWrapper  title="Engagement rate" toolTip="Average engagement rate by followers." selected={engagementValue > 0}>
            <p>More than <strong>{engagementValue}%</strong></p>
            <div className="px-2">
              <Slider value={engagementValue} min={0} max={100} step={1} onChange={(e, newValue) => setEngagementValue(newValue)} />
            </div>
          </FilterWrapper>

          <FilterWrapper  title="Average views" toolTip="Filter creators by average views on top posts." selected={checkActiveRang(averageViewValue)}>
            <CustomRangeFromTo value={averageViewValue} setValue={setAverageViewValue} filteredFromOptions={viewFromOptions} filteredToOptions={viewToOptions} options={averageViewOption} />
          </FilterWrapper>

          <FilterWrapper title="Average likes" toolTip="Filter creators by average likes on top posts." selected={checkActiveRang(likeValue)}>
            <CustomRangeFromTo value={likeValue} setValue={setLikeValue} filteredFromOptions={likeFromOptions} filteredToOptions={likeToOptions} options={averageLikeOption} />
          </FilterWrapper>


          <FilterWrapper title="Average comments" toolTip="Filter creators by average comments on top posts." selected={checkActiveRang(commentValue)}>
            <CustomRangeFromTo value={commentValue} setValue={setCommentValue} filteredFromOptions={commentFromOptions} filteredToOptions={commentToOptions} options={averageLikeOption} />
          </FilterWrapper>

        

          {/* <FilterWrapper disabled title="Follower growth" toolTip="Filter creators who have grown more than the selected percentage in the selected time.">
            <div className="select-inner-box">
              <Autocomplete value={recentGrowth} popupIcon={<ExpandMore fontSize="small" />} options={["<7 days", "<1 month", "<3 months", "<6 months"]} onChange={(e, verify) => setGrowth(verify)} renderInput={(params) => <TextField {...params} label="Follower growth" />} />
              {recentGrowth && (
                <Fragment>
                  <p className="mt-2">More than <strong>{growthRange}%</strong></p>
                  <Slider value={growthRange} onChange={(e, newValue) => setGrowthRange(newValue)} valueLabelDisplay="auto" min={0} max={100} />
                </Fragment>
              )}
            </div>
          </FilterWrapper>
          <FilterWrapper disabled title="Has sponsored post" toolTip="Filter creators which have sponsored posts.">
            <div className="select-checkbox">
              <input type="checkbox" className="check-box" checked={isSponsored} onChange={(event) => {setIsSponsored(event.target.checked);}}/>
              <label className="label-text" htmlFor="sponsored">Only show accounts with sponsored posts</label>
            </div>
          </FilterWrapper>
          <FilterWrapper disabled title="Previous brand sponsors" toolTip="Filter creators which have been sponsored by selected brands.">
            <AutocompleteChips label="Sponsor brands" options={["nike", "makeMyTrip", "rebook", "apollo", "redBus"]} value={brandSponsor} setValue={setBrandSponsor} inputValue={brandSponsorInputValue} setInputValue={setBrandSponsorInputValue} />
          </FilterWrapper> */}
        </ul>
      </div>
    </Col>
  );
};
